var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "things-feature-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("账户安全")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-layout",
            [
              _c(
                "a-layout-content",
                [
                  _c("a-card", [
                    _c(
                      "div",
                      [
                        _c(
                          "a-tabs",
                          {
                            on: { change: _vm.tabHandlerChange },
                            model: {
                              value: _vm.tabsActiveKey,
                              callback: function ($$v) {
                                _vm.tabsActiveKey = $$v
                              },
                              expression: "tabsActiveKey",
                            },
                          },
                          [
                            _c(
                              "a-tab-pane",
                              { key: "PASSWORD", attrs: { tab: "登录密码" } },
                              [
                                _vm.tabsActiveKey === "PASSWORD"
                                  ? _c("password-manager-component")
                                  : _vm._e(),
                              ],
                              1
                            ),
                            !_vm.isEdge
                              ? _c(
                                  "a-tab-pane",
                                  {
                                    key: "VALIDITY_PERIOD",
                                    attrs: { tab: "登录有效期" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "password-manager-operation",
                                      },
                                      [
                                        _vm.validityPeriodMode ===
                                        _vm.ViewModeType.VIEW
                                          ? _c(
                                              "a-button",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeEditMode(
                                                      _vm.ViewModeType.UPDATE
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("编辑")]
                                            )
                                          : [
                                              _c(
                                                "a-button",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changeEditMode(
                                                        _vm.ViewModeType.VIEW
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("取消")]
                                              ),
                                              _c(
                                                "a-button",
                                                {
                                                  staticClass: "m-l-12",
                                                  attrs: { type: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.save(
                                                        "VALIDITY_PERIOD"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("保存")]
                                              ),
                                            ],
                                      ],
                                      2
                                    ),
                                    _vm._l(
                                      _vm.validityPeriodData,
                                      function (item) {
                                        return _c(
                                          "a-card",
                                          {
                                            key: item.identifier,
                                            staticClass:
                                              "password-manager-card",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "password-manager-card-left",
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.title) + " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "password-manager-card-mid",
                                              },
                                              [
                                                item.identifier ===
                                                "SessionPeriod"
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            color: "#a9a6a6",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "默认为1天（24小时），超过有效期系统将要求成员重新登录。管理员可自定义成员账号的登录有效期，配置成功后立即生效"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            color: "#a9a6a6",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "设置最少为1天（24小时），最多为90天（2160小时）"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c("a-switch", {
                                                            attrs: {
                                                              disabled:
                                                                _vm.validityPeriodMode ===
                                                                _vm.ViewModeType
                                                                  .VIEW,
                                                            },
                                                            model: {
                                                              value:
                                                                item.data
                                                                  .custom,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item.data,
                                                                    "custom",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.data.custom",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "a-card",
                                                            {
                                                              staticClass:
                                                                "card-right-inner-card",
                                                              attrs: {
                                                                title:
                                                                  "有效期设置",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "flex",
                                                                    padding:
                                                                      "12px 12px 0px 12px",
                                                                    "flex-direction":
                                                                      "column",
                                                                  },
                                                                },
                                                                [
                                                                  _c("div", [
                                                                    _vm._v(
                                                                      "移动端"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "a-input-number",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              min: 1,
                                                                              max: 90,
                                                                              formatter:
                                                                                function (
                                                                                  value
                                                                                ) {
                                                                                  return (
                                                                                    "" +
                                                                                    (parseInt(
                                                                                      value,
                                                                                      10
                                                                                    ) ||
                                                                                      1)
                                                                                  )
                                                                                },
                                                                              parser:
                                                                                function (
                                                                                  value
                                                                                ) {
                                                                                  return (
                                                                                    parseInt(
                                                                                      value,
                                                                                      10
                                                                                    ) ||
                                                                                    1
                                                                                  )
                                                                                },
                                                                              disabled:
                                                                                _vm.validityPeriodMode ===
                                                                                _vm
                                                                                  .ViewModeType
                                                                                  .VIEW,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item
                                                                                  .data
                                                                                  .mobile,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item.data,
                                                                                    "mobile",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.data.mobile",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          class:
                                                                            _vm.validityPeriodMode ===
                                                                            _vm
                                                                              .ViewModeType
                                                                              .VIEW
                                                                              ? "disabled-span"
                                                                              : "",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "天"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c("div", [
                                                                    _vm._v(
                                                                      "Web端"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "a-input-number",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              min: 1,
                                                                              max: 90,
                                                                              formatter:
                                                                                function (
                                                                                  value
                                                                                ) {
                                                                                  return (
                                                                                    "" +
                                                                                    (parseInt(
                                                                                      value,
                                                                                      10
                                                                                    ) ||
                                                                                      1)
                                                                                  )
                                                                                },
                                                                              parser:
                                                                                function (
                                                                                  value
                                                                                ) {
                                                                                  return (
                                                                                    parseInt(
                                                                                      value,
                                                                                      10
                                                                                    ) ||
                                                                                    1
                                                                                  )
                                                                                },
                                                                              disabled:
                                                                                _vm.validityPeriodMode ===
                                                                                _vm
                                                                                  .ViewModeType
                                                                                  .VIEW,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item
                                                                                  .data
                                                                                  .web,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item.data,
                                                                                    "web",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.data.web",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          class:
                                                                            _vm.validityPeriodMode ===
                                                                            _vm
                                                                              .ViewModeType
                                                                              .VIEW
                                                                              ? "disabled-span"
                                                                              : "",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "天"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c("div", [
                                                                    _vm._v(
                                                                      "大屏端"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "p-b-0",
                                                                      staticStyle:
                                                                        {
                                                                          height:
                                                                            "20px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a-input-number",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              min: 1,
                                                                              max: 90,
                                                                              formatter:
                                                                                function (
                                                                                  value
                                                                                ) {
                                                                                  return (
                                                                                    "" +
                                                                                    (parseInt(
                                                                                      value,
                                                                                      10
                                                                                    ) ||
                                                                                      1)
                                                                                  )
                                                                                },
                                                                              parser:
                                                                                function (
                                                                                  value
                                                                                ) {
                                                                                  return (
                                                                                    parseInt(
                                                                                      value,
                                                                                      10
                                                                                    ) ||
                                                                                    1
                                                                                  )
                                                                                },
                                                                              disabled:
                                                                                _vm.validityPeriodMode ===
                                                                                _vm
                                                                                  .ViewModeType
                                                                                  .VIEW,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item
                                                                                  .data
                                                                                  .unity,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item.data,
                                                                                    "unity",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.data.unity",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          class:
                                                                            _vm.validityPeriodMode ===
                                                                            _vm
                                                                              .ViewModeType
                                                                              .VIEW
                                                                              ? "disabled-span"
                                                                              : "",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "天"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                            _c("div", {
                                              staticClass:
                                                "password-manager-card-right",
                                            }),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c(
                              "a-tab-pane",
                              {
                                key: "SINGLE_USER_LOGIN_LIMIT",
                                attrs: { tab: "多端同登" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "password-manager-operation" },
                                  [
                                    _vm.singleUserLoginLimitMode ===
                                    _vm.ViewModeType.VIEW
                                      ? _c(
                                          "a-button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeEditMode(
                                                  _vm.ViewModeType.UPDATE
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("编辑")]
                                        )
                                      : [
                                          _c(
                                            "a-button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.changeEditMode(
                                                    _vm.ViewModeType.VIEW
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("取消")]
                                          ),
                                          _c(
                                            "a-button",
                                            {
                                              staticClass: "m-l-12",
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.save(
                                                    "SINGLE_USER_LOGIN_LIMIT"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("保存")]
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                                _vm._l(
                                  _vm.singleUserLoginLimitData,
                                  function (item) {
                                    return _c(
                                      "a-card",
                                      {
                                        key: item.identifier,
                                        staticClass: "password-manager-card",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "password-manager-card-left",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.title) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "password-manager-card-mid",
                                          },
                                          [
                                            item.identifier === "LimitStrategy"
                                              ? [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "#a9a6a6",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " 开启后，可对同时在线的同类型终端进行限制（仅可有1台设备），自动登出较早时间登录的账号 "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("a-switch", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.singleUserLoginLimitMode ===
                                                            _vm.ViewModeType
                                                              .VIEW,
                                                        },
                                                        model: {
                                                          value:
                                                            item.data.enable,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item.data,
                                                              "enable",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.data.enable",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                        _c("div", {
                                          staticClass:
                                            "password-manager-card-right",
                                        }),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }