

















































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import AccountSecurityService from '@common-src/service/account-security';
import { ViewModeType } from '@common-src/model/enum';
import { message } from 'ant-design-vue';

@Component({
    components: {}
})
export default class PasswordManagerComponent extends TableDialogFormComponent<any, any> {
    ViewModeType = ViewModeType;
    currentPageViewType = ViewModeType.VIEW;
    passwordManagerDataClone: any = [];
    passwordManagerData = [
        {
            title: '初始密码设置',
            identifier: 'InitialPassword',
            data: {
                default: undefined
            }
        },
        {
            title: '要求成员设置密码',
            identifier: 'FirstLoginReset',
            data: {
                firstLoginReset: false
            }
        },
        {
            title: '密码设置规则',
            identifier: 'PasswordStrengthPolicy',
            data: {
                custom: false, //
                digital: true, // 是否包含数字
                alphabet: true, // 是否包含英文字符
                bothCase: true, // 是否必须含大小写
                special: false, // 是否特殊符号
                minimum: 0, // 密码长度 最小
                maximum: 0 // 密码长度 最大
            }
        },
        {
            title: '定期更新登录密码',
            identifier: 'ResetInterval',
            data: {
                enable: false,
                interval: 0,
                unit: 'day'
            }
        },
        {
            title: '密码错误次数锁定',
            identifier: 'LockStrategy',
            data: {
                enable: false,
                permanentLockEnable: false, // 是否开启永久锁定
                temporaryLockEnable: false, // 是否启用临时锁定
                lockInterval: 0, // 锁定时长, 单位：分钟
                unit: 'minute', // 锁定时长单位, 目前不生效,
                temporaryLockCounts: 0, // 触发临时锁定
                indefiniteLockCounts: 0 // 触发永久锁定次数
            }
        }
        // {
        //     title: '忘记密码功能',
        //     identifier: 'ForgetPassword',
        //     data: { enable: false }
        // }
    ];

    get Disabled() {
        return this.currentPageViewType === ViewModeType.VIEW;
    }

    mounted() {
        this.passwordManagerDataClone = _.cloneDeep(this.passwordManagerData);
        this.initConfig();
    }

    initConfig() {
        AccountSecurityService.getSafeConfig('PASSWORD').then(res => {
            _.forEach(res, item => {
                const config = _.find(this.passwordManagerData, r => r.identifier === item.identifier);
                config.data = _.mergeWith(config.data, item.value);
            });
            this.passwordManagerDataClone = _.cloneDeep(this.passwordManagerData);
        });
    }

    changeEditMode(type: ViewModeType) {
        this.currentPageViewType = type;
        if (type === ViewModeType.VIEW) {
            this.passwordManagerData = _.cloneDeep(this.passwordManagerDataClone);
        }
    }

    validateConfig(config:any) {
        switch (config.identifier) {
            case 'InitialPassword':
                if (_.isEmpty(config.value.default)) {
                    message.warning('初始密码不能为空');
                    return false;
                }
                break;
            case 'PasswordStrengthPolicy':
                if (config.value.custom) {
                    if (!((config.value.digital && config.value.alphabet) || (config.value.digital && config.value.special) || (config.value.alphabet && config.value.special))) {
                        message.warning('密码设置规则至少包含数字、字母、特殊字符中的两种');
                        return false;
                    }
                }
                break;
            case 'LockStrategy':
                if (config.value.enable) {
                    if (!(config.value.permanentLockEnable || config.value.temporaryLockEnable)) {
                        message.warning('密码错误次数锁定开启后，至少选择一项');
                        return false;
                    }
                }
                break;
        }
        return true;
    }

    save() {
        const body = _.map(this.passwordManagerData, item => {
            return {
                identifier: item.identifier,
                value: item.data
            };
        });
        if (_.every(body, item => this.validateConfig(item))) {
            AccountSecurityService.updateSafeConfig(body).then(res => {
                this.$message.success('保存成功');
                this.initConfig();
                this.currentPageViewType = ViewModeType.VIEW;
            });
        }
    }
}

