








































































































































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { AnnouncementListEntityModel, AnnouncementListFilterModel } from '@common-src/entity-model/announcement-list-entity';
import AnnouncementService from '@common-src/service/announcement';
import { ViewModeType } from '@common-src/model/enum';
import PasswordManagerComponent from './password-manager.vue';
import { ModuleType } from '@common-src/enum/module-type.enum';
import AccountSecurityService from '@common-src/service/account-security';

@Component({
    components: {
        'password-manager-component': PasswordManagerComponent
    }
})
export default class AccountSecurityComponent extends TableDialogFormComponent<any, any> {
    AnnouncementListEntityModel = AnnouncementListEntityModel;
    ViewModeType = ViewModeType;
    tabsActiveKey: string = 'PASSWORD';
    validityPeriodMode = ViewModeType.VIEW;
    singleUserLoginLimitMode = ViewModeType.VIEW;

    get IsEdge() {
        return JTL.moduleType === ModuleType.EDGE;
    }

    validityPeriodData: Array<any> = [{
        title: '登录有效期设置',
        identifier: 'SessionPeriod',
        mode: ViewModeType.VIEW,
        data: { custom: false, mobile: 1, web: 1, unit: 'day', unity: 1 }
    }];

    singleUserLoginLimitData: Array<any> = [{
        title: '多端同登设置',
        identifier: 'LimitStrategy',
        mode: ViewModeType.VIEW,
        data: { enable: false }
    }];
    validityPeriodDataClone: any = [];
    singleUserLoginLimitDataClone: any = [];

    mounted() {
        this.restoreOrAssignmentData();
    }

    restoreOrAssignmentData(isRestore: boolean = false) {
        if (isRestore) {
            this.validityPeriodData = _.cloneDeep(this.validityPeriodDataClone);
            this.singleUserLoginLimitData = _.cloneDeep(this.singleUserLoginLimitDataClone);
        } else {
            this.validityPeriodDataClone = _.cloneDeep(this.validityPeriodData);
            this.singleUserLoginLimitDataClone = _.cloneDeep(this.singleUserLoginLimitData);
        }
    }

    formatConfigDataByIdentifier(identifier: string) {
        switch (identifier) {
            case 'VALIDITY_PERIOD':
                return this.validityPeriodData;
            case 'SINGLE_USER_LOGIN_LIMIT':
                return this.singleUserLoginLimitData;
        }
    }

    changeEditMode(type: ViewModeType) {
        this.validityPeriodMode = type;
        this.singleUserLoginLimitMode = type;

        if (type === ViewModeType.VIEW) {
            this.restoreOrAssignmentData(true);
        }
    }

    tabHandlerChange(identifier: string) {
        this.validityPeriodMode = ViewModeType.VIEW;
        this.singleUserLoginLimitMode = ViewModeType.VIEW;
        if (identifier === 'PASSWORD') {
            return;
        }
        AccountSecurityService.getSafeConfig(identifier).then(res => {
            _.forEach(res, item => {
                const config = _.find(this.formatConfigDataByIdentifier(identifier), r => r.identifier === item.identifier);
                config.data = _.mergeWith(config.data, item.value);
            });
            this.restoreOrAssignmentData();
        });
    }

    save(identifier:string) {
        const body: any = _.map(this.formatConfigDataByIdentifier(identifier), item => {
            return {
                identifier: item.identifier,
                value: item.data
            };
        });

        AccountSecurityService.updateSafeConfig(body).then(res => {
            this.$message.success('保存成功');
            this.tabHandlerChange(identifier);
        });
    }
}

